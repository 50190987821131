import React, { Component } from 'react'
import { Grid } from '@mui/material'
import Drinks1 from "../../images/drinks1.jpg"
import Drinks4 from "../../images/drinks4.jpg"
import Team from "../../images/team.jpeg"
import '../About_Us/about_us.scss'
import EventSeat from '@mui/icons-material/EventSeatTwoTone';
import EmojiFoodBeverage from '@mui/icons-material/EmojiFoodBeverageTwoTone';
import PeopleAlt from '@mui/icons-material/PeopleAltTwoTone';
import EventAvailable from '@mui/icons-material/EventAvailableTwoTone';
import Contact from "../Home/Contact"
import Carousel from 'react-bootstrap/Carousel'
import "bootstrap/dist/css/bootstrap.min.css";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import car1 from '../../images/car1.png'
import car2 from '../../images/car2.png'
import car3 from '../../images/car3.png'
export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgArr: [
                {
                    img: car1
                },
                {
                    img: car2
                },
                {
                    img: car3
                },
            ]
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Grid container>
                <Grid item xs={12} className='header' >
                    <Grid container>
                        <Grid item xs={1} md={1} />
                        <Grid item className='item1' xs={10} md={4}>
                            <h2 className='textstyle'>A few words about us</h2>
                        </Grid>
                        <Grid item xs={1} md={1} />
                        <Grid className='extraGrid' item xs={2} md={1} />
                        <Grid item className='item2' xs={8} md={5}>
                            <Grid>
                                <h6 className='item2Containt'>WE ARE OPEN LATE NIGHT</h6>
                                <h3 className='item2Containt2'>12pm - 5am</h3>
                            </Grid>
                        </Grid>
                        <Grid className='extraGrid' item xs={2} md={1} />
                        <Grid xs={1} />

                    </Grid>
                </Grid>
                <Grid item xs={12} pt={2}>
                    <Grid container>
                        <Grid item xs={1} />
                        <Grid item xs={10} md={4}>
                            <Grid>
                                <h6 className='themecolormain'>OUR MOTO —</h6>
                            </Grid>
                            <Grid>
                                <h2 className='themecolor2main'>Mixing joy in every glass, our cocktail lounge stirs up happiness with passion and flavor</h2>
                            </Grid>
                            <Grid>
                                <p className='aboutParagraphmain'>
                                    This motto encapsulates our pledge to deliver a delightful and positive experience for patrons at our bistro. The expression "crafting delight in every sip" conveys our commitment to generating moments of happiness through our diverse beverage offerings. "Infusing happiness with passion and taste" highlights our dedication to concocting premium and flavorful drinks with an authentic zeal for the art of mixology.</p>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid className='extraGrid' item xs={1} />
                        <Grid item xs={10} md={5}>
                            <Grid>
                                <p className='aboutParagraphmain2'>In the heart of the city's nocturnal pulse,
                                    Raadium Café stands as a beacon of warmth and radiance,
                                    welcoming those who seek refuge from the shadows of the night. As the sun dips below the skyline,
                                    the café awakens, bathed in the soft glow of its namesake.</p>
                            </Grid>
                            <Grid>
                                <p className='aboutParagraphmain'>The entrance, adorned with a neon sign that flickers like a distant star,
                                    beckons night owls to step into a world where the ordinary transforms into the extraordinary.
                                    The interior of Raadium is a symphony of muted colors and ambient lighting,
                                    creating an otherworldly ambiance that envelops patrons in a cozy embrace.</p>
                            </Grid>
                            <Grid>
                                <p className='aboutParagraphmain'>The air is infused with the alluring scent of freshly brewed coffee,
                                    and the baristas, like alchemists, craft potions that tantalize the taste buds.
                                    From the signature Raadium Roast to the velvety Moonlight Mocha,
                                    each sip is an invitation to explore new dimensions of flavor.</p>
                            </Grid>
                            <Grid>
                                <p className='aboutParagraphmain'>In the heart of the city, Raadium Café stands as a testament to the allure of the night,
                                    where the ordinary becomes extraordinary,
                                    and every cup is a sip of the luminous magic that unfolds when the world embraces the quietude of the nocturnal hours.</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className='imagecontainer'>
                        <Grid item xs={1} />
                        <Grid item xs={10} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <img src={Drinks1} alt=''
                                style={{ width: '45%', height: 'auto', objectFit: 'contain' }}
                            />
                            <img src={Drinks4} alt=''
                                style={{ width: '45%', height: 'auto', objectFit: 'contain' }}
                            />
                        </Grid>
                        <Grid item xs={1} />

                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container className='thridContainer' pt={10}>
                        <Grid item xs={0.7} />
                        <Grid item xs={2.5} >
                            <EventSeat style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Take a Seat in Style</h5>
                            <h5 className='heading1' width={200}>Relax in style at our café.
                                Choose a cozy spot, sip your favorite drink,
                                and savor the welcoming ambiance we offer</h5>
                        </Grid>
                        <Grid item xs={0.2} />
                        <Grid item xs={2.5} >
                            <EmojiFoodBeverage style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Iconic Delights</h5>
                            <h5 className='heading1' width={200}>Sensational flavors and
                                culinary mastery unite in Iconic Delights,
                                creating an unforgettable dining experience for all</h5>
                        </Grid>
                        <Grid item xs={0.2} />
                        <Grid item xs={2.5} >
                            <PeopleAlt style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Exceptional Hospitality</h5>
                            <h5 className='heading1' width={200}>Dedicated team,
                                elevating your cafe experience with exceptional service,
                                warmth, and a passion for hospitalityr</h5>
                        </Grid>
                        <Grid item xs={0.2} />
                        <Grid item xs={2.5} >
                            <EventAvailable style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Celebrations Unleashed</h5>
                            <h5 className='heading1' width={200}>Discover joy in our vibrant
                                cafe with a calendar full of memorable
                                events and celebrations</h5>
                        </Grid>
                        <Grid item xs={0.7} />
                    </Grid>
                    <Grid container className='thridContainer2' pt={10}>
                        <Grid item xs={0.6} />
                        <Grid item xs={5.2} >
                            <EventSeat style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Take a Seat in Style</h5>
                            <h5 className='heading1' width={200}>Relax in style at our café.
                                Choose a cozy spot, sip your favorite drink,
                                and savor the welcoming ambiance we offer</h5>
                        </Grid>
                        <Grid item xs={0.4} />
                        <Grid item xs={5.2} >
                            <EmojiFoodBeverage style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Iconic Delights</h5>
                            <h5 className='heading1' width={200}>Sensational flavors and
                                culinary mastery unite in Iconic Delights,
                                creating an unforgettable dining experience for all</h5>
                        </Grid>
                        <Grid item xs={0.6} />

                        <Grid item xs={0.6} />
                        <Grid item xs={5.2} >
                            <PeopleAlt style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Exceptional Hospitality</h5>
                            <h5 className='heading1' width={200}>Dedicated team,
                                elevating your cafe experience with exceptional service,
                                warmth, and a passion for hospitalityr</h5>
                        </Grid>
                        <Grid item xs={0.4} />
                        <Grid item xs={5.2} >
                            <EventAvailable style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Celebrations Unleashed</h5>
                            <h5 className='heading1' width={200}>Discover joy in our vibrant
                                cafe with a calendar full of memorable
                                events and celebrations</h5>
                        </Grid>
                        <Grid item xs={0.6} />
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container className='carcontainer' pt={5}>
                        <Grid item textAlign={'center'} xs={12} ><h3 className='testHeading'>Our Team</h3></Grid>
                        <Grid item textAlign={'center'} xs={12} ><h3 className='testHeading2'>Meet Our Team</h3></Grid>
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={1} />
                                <Grid item xs={10} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center' }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <img src={Team} alt=''
                                                        style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid item xs={3}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <img src={Drinks1} alt=''
                                                        style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography fontSize={20}
                                                        textTransform={'uppercase'}
                                                        fontFamily={'popins'}
                                                        color={'#a13704'}
                                                        fontWeight={800}
                                                        letterSpacing={2}
                                                    >
                                                        Soumya
                                                    </Typography>
                                                    <Typography fontSize={14}
                                                        textTransform={'uppercase'}
                                                        fontFamily={'popins'}
                                                        color={'#2c2b23'}
                                                        fontWeight={800}
                                                        letterSpacing={2}
                                                    >
                                                        (Soumya)
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <img src={Drinks1} alt=''
                                                        style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography fontSize={20}
                                                        textTransform={'uppercase'}
                                                        fontFamily={'popins'}
                                                        color={'#a13704'}
                                                        fontWeight={800}
                                                        letterSpacing={2}
                                                    >
                                                        Soumya
                                                    </Typography>
                                                    <Typography fontSize={14}
                                                        textTransform={'uppercase'}
                                                        fontFamily={'popins'}
                                                        color={'#2c2b23'}
                                                        fontWeight={800}
                                                        letterSpacing={2}
                                                    >
                                                        (Soumya)
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <img src={Drinks1} alt=''
                                                        style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography fontSize={20}
                                                        textTransform={'uppercase'}
                                                        fontFamily={'popins'}
                                                        color={'#a13704'}
                                                        fontWeight={800}
                                                        letterSpacing={2}
                                                    >
                                                        Soumya
                                                    </Typography>
                                                    <Typography fontSize={14}
                                                        textTransform={'uppercase'}
                                                        fontFamily={'popins'}
                                                        color={'#2c2b23'}
                                                        fontWeight={800}
                                                        letterSpacing={2}
                                                    >
                                                        (Soumya)
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container className='carcontainer' pt={10} pb={10}>
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            {/* <Slider {...settings} >
                                {this.state.imgArr.map((data, index) => (
                                    <div key={index}>
                                        <div
                                            className="banner-background"
                                            style={{
                                                backgroundImage: `url(${data.img})`,
                                            }}
                                        ></div>
                                    </div>
                                ))}
                            </Slider> */}
                            <Grid item textAlign={'center'} xs={12} ><h3 className='testHeading'>Testimonial</h3></Grid>
                            <Grid item xs={12}>
                                <Carousel slide={true} interval={1000} variant='dark' style={{ justifyContent: 'center', color: "#e6852b" }}>
                                    <Carousel.Item style={{ textAlign: 'center', margin: 'auto' }}>
                                        <img

                                            width={"70%"}
                                            src={car1}
                                            alt="Second slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item style={{ textAlign: 'center' }}>
                                        <img

                                            width={"70%"}
                                            src={car2}
                                            alt="Second slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item style={{ textAlign: 'center' }}>
                                        <img

                                            width={"70%"}
                                            src={car3}
                                            alt="Second slide"
                                        />
                                    </Carousel.Item>
                                </Carousel>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Contact />
                </Grid>
            </Grid>
        )
    }
}
