import React, { Component } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import "./style/about.css";
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import abimg from '../../images/GPR_2116.jpg';
export default class home2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 1,
            count1: 1,
            count2: 90,
        };
    }

    componentDidMount() {
        this.intervalId = setInterval(() => {
            if (this.state.count < 4) {
                this.setState((prevState1) => ({ count: prevState1.count + 1 }));
            } else {
                clearInterval(this.intervalId);
            }
        }, 500); // Increase every second (adjust as needed)
        this.intervalId1 = setInterval(() => {
            if (this.state.count1 < 400) {
                this.setState((prevState1) => ({ count1: prevState1.count1 + 1 }));
            } else {
                clearInterval(this.intervalId1);
            }
        }, 5);
        this.intervalId2 = setInterval(() => {
            if (this.state.count2 < 1000) {
                this.setState((prevState2) => ({ count2: prevState2.count2 + 2 }));
            } else {
                clearInterval(this.intervalId2);
            }
        }, 1);
    }
    render() {
        return (
            <Grid container className='home2'>
                <Grid item xs={1} md={1} />
                <Grid item xs={11} md={5}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Grid>
                                <h6 className='themecolor'>ABOUT US —</h6>
                            </Grid>
                            <Grid>
                                <h2 className='themecolor2'>A few words about our cafe</h2>
                            </Grid>
                            <Grid>
                                <p className='aboutParagraph'>
                                    Welcome to Raadium, an exhilarating blend of flavor, flair, and nightlife. Dive into a world where dazzling neon meets a kaleidoscope of dishes, cocktails, and hookah experiences. At Raadium, we're not just about extending your night; we're about transforming it into a vibrant festival of culinary delights and mesmerizing moments. Step into our realm, where every visit is a journey through the ultimate fusion of tastes, aromas, and the electrifying pulse of the night.
                                </p>
                                <p className='aboutParagraph2'>
                                Welcome to Raadium, where neon nights blend with gourmet dishes, crafted cocktails, and hookah. Step into an evening redefined—your ultimate destination for taste, aroma, and vibrant energy.
                                </p>
                            </Grid>
                            <Grid>
                                <Link to="/aboutus" style={{ textDecoration: 'none' }}>
                                    <Button className='abtbtn' variant='contained' disableRipple disableElevation>
                                        More About US
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11} md={5}>
                    <Grid container>
                        <Grid className='extraGrid' item xs={1} sm={1} />
                        <Grid item xs={5}>
                            <Grid mt={8} display={'flex'} flexDirection={'column'} >
                                <span className='counter1'>{this.state.count + ".0"}</span>
                                <span >Google Rating</span>
                            </Grid>
                            <Grid mt={8} display={'flex'} flexDirection={'column'} >
                                <span className='counter1'>{this.state.count1}+</span>
                                <span >Reviews</span>
                            </Grid>
                            <Grid mt={8} display={'flex'} flexDirection={'column'}>
                                <span className='counter1'>{this.state.count2}+</span>
                                <span >Delight Customer</span>
                            </Grid>
                        </Grid>
                        <Grid className='extraGrid' item xs={0.5} sm={0.5} />
                        <Grid item xs={5}>
                            <Grid mt={8} display={'flex'} flexDirection={'column'}>
                                <Card className='aboutmedia'>
                                    <CardActionArea>
                                        <CardMedia
                                            className='cardimg1'
                                            component="img"
                                            height="140"
                                            image={abimg}
                                            alt="food"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Raadium
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Where every dish tells a story of passion, creativity, and deliciousness.
                                                Visit Raadium Cafe and taste the awesome flavors now
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Card className='aboutmedia2'>
                                    <CardActionArea>
                                        <CardMedia
                                            className='cardimg1'
                                            component="img"
                                            height="240"
                                            image={abimg}
                                            alt="food"
                                        />
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid className='extraGrid' item xs={0.5} sm={0.5} />

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        )
    }
}
