import React, { Component } from 'react'
import { Typography, Grid } from '@mui/material';
import facebook from '../../images/facebook.png';
import justDial from '../../images/justdial.png';
import zomato from '../../images/zomato.png';
import instagram from '../../images/instagram.png';
import './style/contact.scss';

export default class Footer extends Component {
    render() {
        return (
            <Grid>
                <Grid container className='con2' paddingBottom={5}>
                    <Grid item xs={1.5} />
                    <Grid item xs={9} textAlign={'center'}>
                        <iframe
                            title='Raadium Map'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3740.6414692632397!2d85.88608517501221!3d20.356424881129964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190bb17cf1ece7%3A0x59b1e9854a7371b6!2sRaadium%20Cafe!5e0!3m2!1sen!2sin!4v1709653447690!5m2!1sen!2sin"
                            width='100%'
                            height="250"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade" />
                    </Grid>
                    <Grid item xs={1.5} />
                </Grid>
                <Grid container>
                    <Grid className='con' item xs={12} sm={12} md={1} />
                    <Grid className='con' item xs={12} sm={12} md={3} textAlign={'center'}>
                        <Typography variant='h7' className='headerContact'>DO YOU HAVE A QUESTIONS?</Typography>
                        <Typography className='body'>www.raadiumcafe.co.in</Typography>
                    </Grid>
                    <Grid className='con' item xs={12} sm={12} md={5} textAlign={'center'}>
                        <p className='body'>
                            ABC House, 6th Floor,<br /> Roof top,
                            Plot No. 432, Phulnakhra, NH-16,
                            Bhubaneswar, Odisha
                            {/* <hr /> */}
                        </p>
                        <Grid container justifyContent={'space-evenly'} marginTop={5}>
                            <Grid item xs={3}>
                                <a href='https://www.justdial.com/Cuttack/Raadium-Cafe/0671PX671-X671-221015033033-F1X5_BZDET' style={{ textDecoration: "none" }}>
                                    <img width={'35px'} className='iconbtn' alt='justDial' src={justDial} />
                                </a>
                            </Grid>
                            <Grid item xs={3}>
                                <a href='https://www.instagram.com/raadiumcafe8/?hl=en' style={{ textDecoration: "none" }}>
                                    <img width={'35px'} className='iconbtn' alt='instagram' src={instagram} />
                                </a>
                            </Grid>
                            <Grid item xs={3}>
                                <a href='https://www.zomato.com/bhubaneswar/raadium-cafe-pahala-bhubaneshwar' style={{ textDecoration: "none" }}>
                                    <img width={'35px'} className='iconbtn' alt='justDial' src={zomato} />
                                </a>
                            </Grid>
                            <Grid item xs={3}>
                                <a href='https://www.facebook.com/raadium/' style={{ textDecoration: "none" }}>
                                    <img width={'35px'} className='iconbtn' alt='facebook' src={facebook} />
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className='con' item xs={12} sm={12} md={3} textAlign={'center'}>
                        <Typography variant='h7' className='headerContact'>BOOK A TABLE?</Typography>
                        <Typography className='body'>+91 9836 329 400</Typography>
                        <Typography className='body'>+91 9836 328 400</Typography>
                    </Grid>
                </Grid>
                {/* <Divider />
                <Grid container className='con'>
                    <Grid xs={1.5} />
                    <Grid item xs={9} textAlign={'center'}>
                        <Typography>
                             © 2019 Powered by Raadium Cafe 
                        </Typography>
                    </Grid>
                    <Grid xs={1.5} />
                </Grid> */}
            </Grid>
        )
    }
}
