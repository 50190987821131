import React, { Component } from 'react';
import { Grid, Typography, } from '@mui/material';
import view1 from '../../images/view0.jpg';
import view2 from '../../images/view5.jpg';
import view3 from '../../images/view2.jpg';
import view4 from '../../images/view4.jpg';
import seat1 from '../../images/seat1.jpg';
import seat2 from '../../images/seat2.jpg';
import seat3 from '../../images/seat3.jpg';
import seat4 from '../../images/view1.jpg';
import food1 from '../../images/food1.jpg';
import food2 from '../../images/food2.jpg';
import drinks1 from '../../images/drinks11.jpeg';
import drinks2 from '../../images/mix.jpeg';
import papper1 from '../../images/papper1.jpeg';
import "./style/gallery.css"
import Contact from '../Home/Contact'

export default class gallery extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

    }
    render() {
        return (
            <Grid container paddingLeft={2}>
                <Grid item xs={12} className='header' >
                    <Grid container>
                        <Grid item xs={1} md={1} />
                        <Grid item className='item1' xs={10} md={4}>
                            <h2 className='textstyle'>Our Gallery</h2>
                        </Grid>
                        <Grid item xs={1} md={1} />
                        <Grid className='extraGrid' item xs={2} md={1} />
                        <Grid item className='item2' xs={8} md={5}>
                            <Grid>
                                <h6 className='item2Containt'>WE ARE OPEN LATE NIGHT</h6>
                                <h3 className='item2Containt2'>12pm - 5am</h3>
                            </Grid>
                        </Grid>
                        <Grid className='extraGrid' item xs={2} md={1} />
                        <Grid xs={1} />

                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container padding={5} mb={5}>
                        <Grid item xs={12} md={6}>
                            <img alt=''
                                className='image'
                                src={view2}
                            />
                        </Grid>
                        <Grid item xs={0.5} />
                        <Grid item xs={12} md={5.5}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <h2 className='themecolor2' style={{ marginBottom: '20px' }}>View from the Cafe</h2>
                                    <Typography variant="h7" color="text.secondary" align='justify'>
                                        Indulge in the enchanting ambiance of our cozy cafe,
                                        where every visit promises a harmonious fusion of nature's beauty and ultimate relaxation.
                                        The panoramic view captures the essence of serenity, providing a tranquil escape from the hustle of daily life.
                                        As you settle into the inviting atmosphere, immerse yourself in the scenic charm that surrounds, creating a perfect backdrop for moments of pure delight.
                                        Savor your favorite beverage, each sip a journey into the heart of tranquility.
                                        Whether alone or in the company of friends, our cafe offers an oasis of calm,
                                        where the blend of nature and relaxation becomes a cherished experience.</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} marginTop={-5} paddingBottom={5}>
                    <Grid container>
                        <Grid item xs={1} md={4} />
                        <Grid item xs={10} md={7.5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} className='image-container'>
                                    <img alt=''
                                        className='image'
                                        src={view1}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className='image-container'>
                                    <img alt=''
                                        className='image'
                                        src={view3}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className='image-container'>
                                    <img alt=''
                                        className='image'
                                        src={view4}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} md={0.5} />
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container padding={5} mb={5}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <h2 className='themecolor2' style={{ marginBottom: '20px' }}>Nature's Vantage: Café Seating with Scenic Views</h2>
                                    <Typography variant="h7" color="text.secondary" >
                                        Discover an unparalleled dining experience at our café,
                                        where every seat offers a captivating glimpse of nature's beauty.
                                        Our thoughtfully designed seating arrangements provide not just comfort,
                                        but a front-row view to the breathtaking outdoors. As you enjoy your meal,
                                        be immersed in the serenity of lush landscapes and picturesque scenes.
                                        Each table becomes a private haven, allowing you to savor delicious cuisine while indulging in the calming panorama.
                                        Whether you're a solo visitor seeking solace or sharing the moment with loved ones, our café invites you to unwind,
                                        relish exquisite flavors,
                                        and connect with the harmonious allure of the natural world..</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={0.5} />
                        <Grid item xs={12} marginBlockEnd={5.5}>
                            <img alt=''
                                className='image'
                                src={seat4}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} marginTop={-5} paddingBottom={5}>
                    <Grid container>
                        <Grid item xs={1} />
                        <Grid item xs={10} marginBlockEnd={7.5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} className='image-container'>
                                    <img alt=''
                                        className='image'
                                        src={seat2}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className='image-container'>
                                    <img alt=''
                                        className='image'
                                        src={seat3}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className='image-container'>
                                    <img alt=''
                                        className='image'
                                        src={seat1}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} md={4} />

                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container padding={5} mb={5}>
                        <Grid item xs={12} md={6}>
                            <img alt=''
                                className='image'
                                src={drinks2}
                            />
                        </Grid>
                        <Grid item xs={0} md={0.5} />
                        <Grid item xs={12} md={5.5}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <h3 className='themecolor21' style={{ marginBottom: '20px' }}>Eat & Sip: Flavorful Delights</h3>
                                    <Typography variant="h7" color="text.secondary" >
                                        Embark on a culinary adventure with our exquisite menu,
                                        where delectable dishes meet artful beverages. At Raadium Cafe,
                                        every bite is a celebration of flavors, meticulously crafted to tantalize your taste buds.
                                        Indulge in a symphony of textures and aromas, from gourmet dishes prepared with the finest
                                        ingredients to handcrafted drinks that awaken your senses.
                                        Whether you're exploring our signature entrees or sipping on expertly
                                        brewed coffees and refreshing cocktails, each experience is a gastronomic delight.
                                        Join us for an unforgettable journey where culinary mastery and beverage artistry harmonize,
                                        creating moments of pure enjoyment and satisfaction.</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} marginTop={-5} paddingBottom={5}>
                    <Grid container>
                        <Grid item xs={1} md={4} />
                        <Grid item xs={10} md={7.5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} className='image-container'>
                                    <img alt=''
                                        className='image'
                                        src={food1}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className='image-container'>
                                    <img alt=''
                                        className='image'
                                        src={drinks1}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className='image-container'>
                                    <img alt=''
                                        className='image'
                                        src={food2}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} md={0.5} />
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container padding={5} mb={5}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <h2 className='themecolor2' style={{ marginBottom: '20px' }}>Raadium Cafe: Where Every Sip Tells a Story</h2>
                                    <Typography variant="h7" color="text.secondary" >
                                        Step into a world of flavor at Raadium Cafe,
                                        where each sip and bite narrates a tale of culinary excellence.
                                        Our enchanting space invites you to indulge in rich aromas,
                                        gourmet delights, and the perfect cup of comfort. From aromatic coffees to delectable pastries,
                                        every offering is crafted with passion and precision. Immerse yourself in the cozy ambiance,
                                        where friendly conversations blend seamlessly with the background music.
                                        Whether you seek a moment of solitude or a casual catch-up, Raadium Cafe promises an escape into a haven of taste and tranquility.
                                        Join us and let your senses embark on a delightful journey</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={0.5} />
                        <Grid item xs={12} md={5.5} className='image-container'>
                            <img alt=''
                                className='image'
                                src={papper1}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Contact />
                </Grid>
            </Grid>
        )
    }
}
