import React, { Component } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { options } from "../constant";
import '../styles/drawer.scss';
import logo from "../../images/logoRemoveBg.png"
import { Link, useLocation } from 'react-router-dom';

class PermanentDrawerLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  render() {

    const CustomListItem = ({ option }) => {
      const location = useLocation();
      const isActive = location.pathname === option.link;

      return (
        <Link to={option.link} style={{ textDecoration: 'none' }}>
          <ListItem
            key={option.name}
            className={`drawer_text ${isActive ? 'active-link' : ''}`}
          >
            {option.name}
          </ListItem>
        </Link>
      );
    };
    // const container =
    //   this.props.window !== undefined
    //     ? () => this.props.window().document.body
    //     : undefined;

    // const drawer = (
    //   <Box onClick={this.handleDrawerToggle} sx={{ textAlign: 'center' }}>
    //     <center>
    //       <img src={logo} alt="Logo" style={{ width: '20%', marginLeft: '10px' }} />
    //     </center>
    //     <Divider />
    //     <List>
    //       {options.map((option) => (
    //         <Link key={option.link} to={option.link} style={{ textDecoration: 'none' }}>
    //           <ListItem key={option.name} className="drawer_text">
    //             {option.name}
    //           </ListItem>
    //         </Link>
    //       ))}
    //     </List>
    //   </Box>
    // );
    return (
      <>
        <Box sx={{ display: 'flex' }} className='side'>
          <CssBaseline />
          <Drawer
            sx={{
              '& .MuiDrawer-paper': {
                width: '20%',
                backgroundColor: '#2c2b23',
                boxSizing: 'border-box',
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <center>
              <img src={logo} alt="Logo" style={{ width: '90%', marginLeft: '10px' }} />
            </center>
            <List className="vertical-center">
              {options.map((option) => (
                <CustomListItem key={option.link} option={option} />
              ))}
            </List>
          </Drawer>;
        </Box>

      </>
    );
  }
}

export default PermanentDrawerLeft;
