import React, { Component } from 'react'
import { Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Hometem from '../../images/serv.jpg';
// import Hometem from '../../images/Coffee.jpg';
import "./style/about.css";

export default class team extends Component {
    render() {
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    {/* <Grid container>
                        <Grid item xs={8}>
                            <img alt='team_mates' src={Hometem} style={{ width: '100%', height: '100%' }} />
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <img alt='team_mates' src={Hometem} style={{ width: '100%', height: '100%' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <img alt='team_mates' src={Hometem} style={{ width: '100%', height: '100%' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <img alt='team_mates' src={Hometem} style={{ width: '100%', height: '100%' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <img alt='team_mates' src={Hometem} style={{ width: '100%', height: '100%' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <img alt='team_mates' src={Hometem} style={{ width: '100%', height: '100%' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <img alt='team_mates' src={Hometem} style={{ width: '100%', height: '100%' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className='home2' style={{ backgroundColor: "#2c2b23" }}>
                    <Grid style={{ padding: '0 15%' }}>
                        <Grid>
                            <h6 className='themecolor'>OUR TEAM —</h6>
                        </Grid>
                        <Grid>
                            <h2 className='themecolor3' style={{ color: 'white', fontSize: '28px' }}>Team Raadium: Nightlife Architects</h2>
                        </Grid>
                        <Grid>
                            <p className='aboutParagraph' style={{ color: 'white' }}>Step into <b>Raadium</b> and meet our tight-knit team dedicated to crafting your perfect experience. From our expert Barista to our creative minds shaping the ambiance, each member plays a vital role. Friendly service, expertly brewed coffee, and creative culinary delights - it's all about creating memorable moments. Join us at <b>Raadium</b>, where every visit is a blend of passion and perfection.</p>
                            <p className='aboutParagraph2' style={{ color: 'white' }}>
                                Meet the heart of <b>Raadium</b>—our dedicated team turning every visit into a masterpiece. From our expert baristas to the creative forces setting the scene, we're united in crafting unforgettable experiences with friendly service, exceptional coffee, and innovative dishes. At Raadium, passion meets perfection in every moment.
                            </p>
                        </Grid>
                        <Grid>
                            <Link to="/aboutus" style={{ textDecoration: 'none' }}>
                                <Button className='abtbtn2' variant='contained' disableRipple disableElevation>
                                    Who Are We?
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
