import React, { Component } from 'react';
import { Grid, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import EventSeat from '@mui/icons-material/EventSeatTwoTone';
import EmojiFoodBeverage from '@mui/icons-material/EmojiFoodBeverageTwoTone';
import PeopleAlt from '@mui/icons-material/PeopleAltTwoTone';
import EventAvailable from '@mui/icons-material/EventAvailableTwoTone';
import view1 from '../../images/view0.jpg';
import view2 from '../../images/view1.jpg';
import "./style/gallery.css"
export default class gallery extends Component {
    render() {
        return (
            <Grid>
                <Grid item xs={12} >
                    <Grid container className='thridContainer' pt={10}>
                        <Grid item xs={0.7} />
                        <Grid item xs={2.5} >
                            <EventSeat style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Take a Seat in Style</h5>
                            <h5 className='heading1' width={200}>Relax in style at our café.
                                Choose a cozy spot, sip your favorite drink,
                                and savor the welcoming ambiance we offer</h5>
                        </Grid>
                        <Grid item xs={0.2} />
                        <Grid item xs={2.5} >
                            <EmojiFoodBeverage style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Iconic Delights</h5>
                            <h5 className='heading1' width={200}>Sensational flavors and
                                culinary mastery unite in Iconic Delights,
                                creating an unforgettable dining experience for all</h5>
                        </Grid>
                        <Grid item xs={0.2} />
                        <Grid item xs={2.5} >
                            <PeopleAlt style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Exceptional Hospitality</h5>
                            <h5 className='heading1' width={200}>Dedicated team,
                                elevating your cafe experience with exceptional service,
                                warmth, and a passion for hospitalityr</h5>
                        </Grid>
                        <Grid item xs={0.2} />
                        <Grid item xs={2.5} >
                            <EventAvailable style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Celebrations Unleashed</h5>
                            <h5 className='heading1' width={200}>Discover joy in our vibrant
                                cafe with a calendar full of memorable
                                events and celebrations</h5>
                        </Grid>
                        <Grid item xs={0.7} />
                    </Grid>
                    <Grid container className='thridContainer2' pt={5}>
                        <Grid item xs={0.6} />
                        <Grid item xs={5.2} >
                            <EventSeat style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Take a Seat in Style</h5>
                            <h5 className='heading1' width={200}>Relax in style at our café.
                                Choose a cozy spot, sip your favorite drink,
                                and savor the welcoming ambiance we offer</h5>
                        </Grid>
                        <Grid item xs={0.4} />
                        <Grid item xs={5.2} >
                            <EmojiFoodBeverage style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Iconic Delights</h5>
                            <h5 className='heading1' width={200}>Sensational flavors and
                                culinary mastery unite in Iconic Delights,
                                creating an unforgettable dining experience for all</h5>
                        </Grid>
                        <Grid item xs={0.6} />

                        <Grid item xs={0.6} />
                        <Grid item xs={5.2} >
                            <PeopleAlt style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Exceptional Hospitality</h5>
                            <h5 className='heading1' width={200}>Dedicated team,
                                elevating your cafe experience with exceptional service,
                                warmth, and a passion for hospitalityr</h5>
                        </Grid>
                        <Grid item xs={0.4} />
                        <Grid item xs={5.2} >
                            <EventAvailable style={{ fontSize: "100px", color: "white" }} />
                            <h5 className='heading1'>Celebrations Unleashed</h5>
                            <h5 className='heading1' width={200}>Discover joy in our vibrant
                                cafe with a calendar full of memorable
                                events and celebrations</h5>
                        </Grid>
                        <Grid item xs={0.6} />
                    </Grid>
                </Grid>
                <Grid container className='gallery-container'>
                    <Grid item xs={1.5} />
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            {/* First item */}
                            <Grid item xs={12} sm={4}>
                                <Paper
                                    className='image-container'
                                >
                                    <img
                                        src={view1}
                                        alt="gallery"
                                        className='image'
                                    />
                                </Paper>
                            </Grid>

                            {/* Second item */}
                            <Grid item xs={12} sm={4}>
                                <Paper
                                    className='image-container middle-item'>
                                    <div className="gallery-info">
                                        <div className="gallery-title">Photo Gallery</div>
                                        <div className="gallery-description">
                                            Explore beautiful and adorable kitten pictures in our gallery.
                                        </div>
                                        <Link to="/gallery" className="redirect-button">
                                            Explore More
                                        </Link>
                                    </div>

                                </Paper>
                            </Grid>

                            {/* Third item */}
                            <Grid item xs={12} sm={4}>
                                <Paper
                                    className='image-container'
                                >
                                    <img
                                        src={view2}
                                        alt="gallery"
                                        className='image'
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1.5} />
                </Grid>
            </Grid>
        )
    }
}
