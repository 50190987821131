import { Grid } from '@mui/material'
import React, { Component } from 'react'
import "./style/home.css"
export default class home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ isVisible: true })
    }

    render() {
        const { isVisible } = this.state

        return (
            < Grid container className='grid-container' >
                <Grid item xs={6} className={`grid-item1 ${isVisible ? 'show' : ''}`}>
                    <Grid container className="grid-item1-box" direction={'row'} >
                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='wellcome'>Welcome<br className='br' />
                                to<br className='br1' /> Raadium Cafe</div>
                            <div className="wellcome-containt">Experience a vibrant and extraordinary<br />
                                Hotspot in the city of Bhubaneswar!<br />
                                Perfect fit to your hang<br />
                                to Raadium</div>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', }} >
                            <div className='weare'>
                                WE ARE OPEN<br />
                                7 DAYS A WEEK
                            </div>
                            <div className='weare2'>
                                12pm - 5am
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className='mobcontent'>
                    <div className='weare3'>
                        12pm - 5am
                    </div>
                </Grid>
            </Grid >
        )
    }
}
