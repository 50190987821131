import React, { Component } from 'react';
import { Grid, Button, Card, CardActionArea, CardMedia, Typography } from '@mui/material';
import "./style/about.css";
import { Link } from 'react-router-dom';
import Drinks2 from '../../images/drinks2.jpg';
import Drinks3 from '../../images/drinks3.jpg';
import Drinks1 from '../../images/drinks1.jpg';
import Drinks4 from '../../images/drinks4.jpg';
import Food1 from '../../images/food1.jpg';
import Food2 from '../../images/food2.jpg';
import Food3 from '../../images/food3.jpg';
import Food4 from '../../images/food4.jpg';
import Food5 from '../../images/food5.jpg';
import Food6 from '../../images/food6.jpg';
import { Carousel } from 'react-bootstrap';

export default class menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: [
                {
                    dish: Drinks2,
                    topic: 'Virgin Mojito',
                    description: 'Sip into freshness with our invigorating Virgin Mojito, a tantalizing blend of muddled mint, zesty lime, and effervescent sparkling water. A refreshing symphony for your palate.',
                    dish2: Drinks3,
                    topic2: 'Icecreams',
                    description2: 'Sundays are sweeter with our indulgent Sunday Ice Cream. Treat yourself to a delightful blend of creamy goodness and heavenly toppings, a perfect finale to your weekend indulgence.'
                },
                {
                    dish: Drinks1,
                    topic: 'Blue Lagoon',
                    description: 'A breathtaking natural pool with vivid blue waters, created by minerals, offering a serene and unique retreat.',
                    dish2: Drinks4,
                    topic2: 'Lemon Ice Tea',
                    description2: 'Refreshing blend of tea, citrusy lemon, and ice; a cool, tangy beverage perfect for a revitalizing thirst-quencher.'
                },
                {
                    dish: Food1,
                    topic: 'Dragon Fry Chicken',
                    description: 'Crispy, flavorful fried chicken with a fiery twist, delivering a perfect blend of spice and succulence in every bite.',
                    dish2: Food2,
                    topic2: 'Drums of Heaven',
                    description2: 'Delectable appetizer, chicken wings frenched and coated, served with zesty sauces—a flavorful, crispy, and popular finger food delight.'
                },
                {
                    dish: Food4,
                    topic: 'Veg Hakka Noddles',
                    description: 'Stir-fried vegetarian noodles, bursting with vibrant veggies and savory flavors, a delightful fusion of Indian-Chinese cuisine.',
                    dish2: Food5,
                    topic2: 'Abbu Chilly Kabab',
                    description2: 'Succulent green marinated kebabs, bursting with herbs and spices, grilled to perfection, delivering vibrant flavors in every bite.'
                },
                {
                    dish: Food3,
                    topic: 'Cheese Chilly Toast',
                    description: 'Spicy blend of melted cheese, crisp veggies, and chili kick, sandwiched between layers of toasted perfection.',
                    dish2: Food6,
                    topic2: 'Margherita Pizza',
                    description2: 'Classic Italian delight with tomato, mozzarella, and basil. A simple yet flavorful combination on a crispy, thin crust.'
                }
            ]
        };
    }

    render() {
        return (
            <Grid container className='home2'>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <center>
                        <h6 className='themecolor'>OUR MENU</h6>
                    </center>
                    <Carousel className='carlarge' slide={true} style={{ marginTop: '20px' }}>
                        {this.state.menu.map((e, index) => (
                            <Carousel.Item key={index}>
                                <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Grid item xs={6}>
                                        <Card style={{ borderRadius: 0 }}>
                                            <CardActionArea>
                                                <CardMedia
                                                    className='cardimg1'
                                                    component="img"
                                                    height="400"
                                                    image={e.dish}
                                                    alt=""
                                                />
                                            </CardActionArea>
                                        </Card>
                                        <h2 className='themecolor2'>{e.topic}</h2>
                                        <Typography variant="body2" color="text.secondary" width={'90%'}>
                                            {e.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card style={{ borderRadius: 0 }}>
                                            <CardActionArea>
                                                <CardMedia
                                                    className='cardimg1'
                                                    component="img"
                                                    height="400"
                                                    image={e.dish2}
                                                    alt=""
                                                />
                                            </CardActionArea>
                                        </Card>
                                        <h2 className='themecolor2'>{e.topic2}</h2>
                                        <Typography variant="body2" color="text.secondary" width={'90%'}>
                                            {e.description2}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    <Carousel className='carsmall' slide={true} style={{ marginTop: '20px' }}>
                        {this.state.menu.map((e, index) => (
                            <Carousel.Item key={index}>
                                <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Grid item xs={12}>
                                        <Card style={{ borderRadius: 0 }}>
                                            <CardActionArea>
                                                <CardMedia
                                                    className='cardimg1'
                                                    component="img"
                                                    height="400"
                                                    image={e.dish}
                                                    alt=""
                                                />
                                            </CardActionArea>
                                        </Card>
                                        <h2 className='themecolor2'>{e.topic}</h2>
                                        <Typography variant="body2" color="text.secondary" width={'90%'}>
                                            {e.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    <Grid style={{ margin: '45px' }}>
                        <center>
                            <Link to="/menu" style={{ textDecoration: 'none' }}>
                                <Button className='abtbtn' variant='contained' disableRipple disableElevation>
                                    SEE FULL MENU
                                </Button>
                            </Link>
                        </center>
                    </Grid>
                </Grid>
                <Grid item xs={1} />
            </Grid >
        )
    }
}
