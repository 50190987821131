import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// A dummy reducer is required, but it won't actually manage any state
const dummyReducer = (state = {}, action) => state;

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

// Configure store with dummy reducer
const configureStore = (initialState) => createStoreWithMiddleware(dummyReducer, initialState);

export default configureStore;
