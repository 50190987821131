/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Drawer_option_con from "../components/drawer";
import AppDrawer from "../components/AppDrawer";
import Error from "../../404";
import Grid from '@mui/material/Grid';

import Home from "../../pages/Home/home";
import HAbout from "../../pages/Home/about";
import HContact from "../../pages/Home/Contact";
import HTeam from '../../pages/Home/team';
import HMenu from '../../pages/Home/menu';
import HGallery from '../../pages/Home/gallery';

import Contact_Us from "../../pages/Contact_Us/contact_us";

import About_Us from "../../pages/About_Us/about_us";

import Menu from "../../pages/Menu/Menu";
import Gallery from "../../pages/Gallery/gallery";

class Routers extends Component {
  render() {
    let body;

    body = (
      <Grid container
        style={{
          display: "flex",
        }}
      >
        
        <Grid className="secondGrid" item xs={0} md={2}
          bgcolor={'#2c2b23'}>
          <Drawer_option_con />
        </Grid>
        <Grid className="thirdGrid" 
          >
          <AppDrawer />
        </Grid>
        <Grid item xs={12} sm={12} md={10}>
          <main
            style={{
              flexGrow: 1,
              // marginLeft: 25,
            }}
          >
            <Routes>
              <Route exact path="/" element={<div><Home /><HAbout /><HTeam /><HMenu /><HGallery /><HContact /></div>} />
              <Route exact path="/aboutus" element={<div><About_Us /></div>} />
              <Route exact path="/contact" element={<div><Contact_Us /></div>} />
              <Route exact path="/gallery" element={<div><Gallery /></div>} />
              <Route exact path="/appd" element={<div><AppDrawer /></div>} />
              <Route exact path="/menu" element={<div><Menu /></div>} />
              <Route exact path="*" element={<Error />} />
            </Routes>
          </main>
        </Grid>
      </Grid>
    );

    return <Router>{body}</Router>;
  }
}

export default Routers;
