import React, { Component } from 'react'
import { Grid, Card, CardMedia, Typography, CardActionArea } from '@mui/material'
import { Carousel } from 'react-bootstrap';
import '../styles/header.scss'
import Contact from '../Home/Contact';
import '../Home/style/contact.scss'
import '../Home/style/about.css'

import Drinks2 from '../../images/drinks2.jpg';
import Drinks3 from '../../images/drinks3.jpg';
import Drinks1 from '../../images/drinks1.jpg';
import Drinks4 from '../../images/drinks4.jpg';

import Food1 from '../../images/food1.jpg';
import Food2 from '../../images/food2.jpg';
import Food3 from '../../images/food3.jpg';
import Food4 from '../../images/food4.jpg';
import Food5 from '../../images/food5.jpg';
import Food6 from '../../images/food6.jpg';

import Page1 from '../../images/food menu/front page.jpg';
import Page2 from '../../images/food menu/page 1.jpg';
import Page3 from '../../images/food menu/page 2.jpg';
import Page4 from '../../images/food menu/page 3.jpg';
import Page5 from '../../images/food menu/page 4.jpg';
import Page6 from '../../images/food menu/page 5.jpg';
import Page7 from '../../images/food menu/page 6.jpg';
import Page8 from '../../images/food menu/page 7.jpg';
import Page9 from '../../images/food menu/page 8.jpg';
import Page10 from '../../images/food menu/page 9.jpg';
import Page11 from '../../images/food menu/page 10.jpg';
import Page12 from '../../images/food menu/page 11.jpg';
import Page13 from '../../images/food menu/page 12.jpg';
import Page14 from '../../images/food menu/back page.jpg';

import FoodPage1 from '../../images/beverages/front.jpg';
import FoodPage2 from '../../images/beverages/page 1.jpg';
import FoodPage3 from '../../images/beverages/page 2.jpg';
import FoodPage4 from '../../images/beverages/page 3.jpg';
import FoodPage5 from '../../images/beverages/page 4.jpg';
import FoodPage6 from '../../images/beverages/back.jpg';

import Hookah1 from '../../images/hm.png';
import Hookah2 from '../../images/hm2.png';

export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: true,
            drink: [
                {
                    dish: Drinks2,
                },
                {
                    dish: Drinks3,
                },
                {
                    dish: Drinks1,
                },
                {
                    dish: Drinks4,

                },
            ],
            food: [
                {
                    dish: Food1,
                },
                {
                    dish: Food2,
                },
                {
                    dish: Food3,
                },
                {
                    dish: Food4,
                },
                {
                    dish: Food5,
                },
                {
                    dish: Food6,
                },
            ],
            foodmenu: [
                {
                    page: Page1,
                },
                {
                    page: Page2,

                },
                {
                    page: Page3,
                },
                {
                    page: Page4,

                },
                {
                    page: Page5,
                },
                {
                    page: Page6,

                },
                {
                    page: Page7,
                },
                {
                    page: Page8,

                },
                {
                    page: Page9,
                },
                {
                    page: Page10,

                },
                {
                    page: Page11,
                },
                {
                    page: Page12,

                },
                {
                    page: Page13,
                },
                {
                    page: Page14,

                },
            ],
            menucard: [
                {
                    page: FoodPage1,
                },
                {
                    page: FoodPage2,

                },
                {
                    page: FoodPage3,
                },
                {
                    page: FoodPage4,

                },
                {
                    page: FoodPage5,

                },
                {
                    page: FoodPage6,

                },
            ],
            hookah: [
                {
                    hookah: Hookah1,
                    hookah2: Hookah2,
                },
            ],
            HookaMenu: [
                {
                    hookah: Hookah1,
                },
                {
                    hookah: Hookah2,
                },
            ]
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Grid container >
                <Grid item xs={12} className='header' >
                    <Grid container>
                        <Grid item xs={1} md={1} />
                        <Grid item className='item1' xs={10} md={4}>
                            <h2 className='textstyle'>Hookah vibes, tasty bites</h2>
                        </Grid>
                        <Grid item xs={1} md={1} />
                        <Grid className='extraGrid' item xs={2} md={1} />
                        <Grid item className='item2' xs={8} md={5}>
                            <Grid>
                                <h6 className='item2Containt'>WE ARE OPEN LATE NIGHT</h6>
                                <h3 className='item2Containt2'>12pm - 5am</h3>
                            </Grid>
                        </Grid>
                        <Grid className='extraGrid' item xs={2} md={1} />
                        <Grid xs={1} />

                    </Grid>
                </Grid>
                <Grid >
                    <Grid item xs={12} className='menubg'>
                        <Grid container marginTop={5} marginBottom={5}>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                {/* <Carousel className='carlarge' slide={true} style={{ marginTop: '20px' }}>
                                    {this.state.hookah.map((e, index) => (
                                        <Carousel.Item key={index}>
                                            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                <Grid item xs={5}>
                                                    <Card style={{ borderRadius: 0 }}>
                                                        <CardActionArea>
                                                            <CardMedia
                                                                className='cardimg1'
                                                                component="img"
                                                                image={e.hookah}
                                                                alt=""
                                                            />
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                                <Grid itm xs={2}/>
                                                <Grid item xs={5}>
                                                    <Card style={{ borderRadius: 0 }}>
                                                        <CardActionArea>
                                                            <CardMedia
                                                                className='cardimg1'
                                                                component="img"
                                                                image={e.hookah2}
                                                                alt=""
                                                            />
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Carousel.Item>
                                    ))}
                                </Carousel> */}
                                <Carousel 
                                // className='carsmall' 
                                slide={true} style={{ marginTop: '20px' }}>
                                    {this.state.HookaMenu.map((e, index) => (
                                        <Carousel.Item key={index}>
                                            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                <Grid item xs={3} />
                                                <Grid item xs={6}>
                                                    <Card style={{ borderRadius: 0 }}>
                                                        <CardActionArea>
                                                            <CardMedia
                                                                className='cardimg1'
                                                                component="img"
                                                                image={e.hookah}
                                                                alt=""
                                                            />
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={3} />
                                            </Grid>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container padding={10}>
                            <Grid item xs={12} md={6}>
                                <Grid container borderRadius={20}>
                                    {this.state.drink.map((e, index) => (
                                        <Grid item xs={6} padding={0.1}>
                                            <CardMedia
                                                component="img"
                                                height='100%'
                                                image={e.dish}
                                                alt=""
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={1} />
                            <Grid item xs={12} md={5}>
                                <h2 className='themecolor2' style={{ marginBottom: '20px' }}>Beverages</h2>
                                <Typography variant="h7" color="text.secondary" >
                                    Indulge in our diverse selection of beverages, from refreshing virgin mojitos to rich, aromatic coffees. Each sip is a journey of flavors, carefully crafted to tantalize your taste buds. Whether you crave a revitalizing concoction or a comforting classic, our beverages elevate your dining experience with every delightful and satisfying pour.
                                </Typography>
                                <br />
                                <Carousel slide={true} style={{ marginTop: '20px' }}>
                                    {this.state.menucard.map((e, index) => (
                                        <Carousel.Item key={index}>
                                            <Grid container style={{ justifyContent: 'center' }}>
                                                <Grid item xs={12}>
                                                    <Card style={{ borderRadius: 0, }}>
                                                        <CardMedia
                                                            component="img"
                                                            style={{ height: '100%' }}
                                                            image={e.page}
                                                            alt=""
                                                        />
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} marginBottom={10}>
                        <Grid container paddingLeft={10} paddingRight={10}>
                            <Grid item xs={12} md={5}>
                                <h2 className='themecolor2' style={{ marginBottom: '20px' }}>Cuisines</h2>
                                <Typography variant="h7" color="text.secondary" >
                                    Cuisine is the artful practice of preparing and presenting food. It encompasses diverse culinary traditions, techniques, and flavors, reflecting cultural richness. A fusion of creativity and skill, cuisine celebrates taste, texture, and aesthetics, inviting gastronomic exploration that delights the senses and brings people together through shared culinary experiences.
                                </Typography>
                                <br />
                                <Carousel slide={true} style={{ marginTop: '20px' }}>
                                    {this.state.foodmenu.map((e, index) => (
                                        <Carousel.Item key={index}>
                                            <Grid container style={{ justifyContent: 'center' }}>
                                                <Grid item xs={12}>
                                                    <Card style={{ borderRadius: 0, }}>
                                                        <CardMedia
                                                            component="img"
                                                            style={{ height: '100%' }}
                                                            image={e.page}
                                                            alt=""
                                                        />
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </Grid>
                            <Grid item xs={12} md={1} />
                            <Grid item xs={12} md={6} >
                                <Grid container borderRadius={20}>
                                    {this.state.food.map((e, index) => (
                                        <Grid item xs={6} sm={6} padding={0.1}>
                                            <CardMedia
                                                component="img"
                                                height='100%'
                                                image={e.dish}
                                                alt=""
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Contact />
                </Grid>
            </Grid>
        )
    }
}
