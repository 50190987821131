export const options = [
    { id: 0, name: "HOME"  , icon: '', link: "/" },
    { id: 1, name: "MENU", icon: '', link: "/menu" },
    { id: 2, name: "GALLERY", icon: '', link: "/gallery" },
    { id: 3, name: "CONTACT", icon: '', link: "/contact" },
    { id: 4, name: "ABOUT US", icon: '', link: '/aboutus'},
];




