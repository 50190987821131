import React, { Component } from 'react'
import { Grid, TextField, Button } from '@mui/material'
import './contact_us.scss'
import '../styles/header.scss'
import Contact from '../Home/Contact'

export default class about_us extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Grid container>
        <Grid item xs={12} className='header' >
          <Grid container>
            <Grid item xs={1} md={1} />
            <Grid item className='item1' xs={10} md={4}>
              <h2 className='textstyle'>Feel free to contact us</h2>
            </Grid>
            <Grid item xs={1} md={1} />
            <Grid className='extraGrid' item xs={2} md={1} />
            <Grid item className='item2' xs={8} md={5}>
              <Grid>
                <h6 className='item2Containt'>WE ARE OPEN LATE NIGHT</h6>
                <h3 className='item2Containt2'>12pm - 5am</h3>
              </Grid>
            </Grid>
            <Grid className='extraGrid' item xs={2} md={1} />
            <Grid xs={1} />

          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className='GridContainer'>
            <Grid item md={0.6} sm={0} xs={0} />
            <Grid item md={2.4} sm={5.5} xs={12}>
              <Grid container mt={1}>

                <Grid item xs={3} sm={12} md={12}>
                  <img src="https://themes.muffingroup.com/be/cafe3/wp-content/uploads/2020/02/cafe3-contact-icon1.png" alt="cafe3-contact-icon1" title="" width="58" height="58" />
                </Grid>
                <Grid item xs={9} sm={12} md={12}>
                  <div>
                    <h4 className='texthead'>CONTACT INFO</h4>
                    <p>(+91) 9836 329 400</p>
                    <p>(+91) 9836 328 400</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={0.6} sm={1} xs={0} />
            <Grid item md={2.4} sm={5.5} xs={12}>
              <Grid container mt={1}>

                <Grid item xs={3} sm={12} md={12}>
                  <img src="https://themes.muffingroup.com/be/cafe3/wp-content/uploads/2020/02/cafe3-contact-icon2.png" alt="cafe3-contact-icon2" title="" width="58" height="58" />
                </Grid>
                <Grid item xs={9} sm={12} md={12}>
                  <div>
                    <h4 className='texthead'>EMAIL INFO</h4>
                    <p>www.raadiumcafe.co.in</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={0.6} sm={0} xs={0} />
            <Grid item md={2.4} sm={5.5} xs={12}>
              <Grid container mt={1}>

                <Grid item xs={3} sm={12} md={12}>
                  <img src="https://themes.muffingroup.com/be/cafe3/wp-content/uploads/2020/02/cafe3-contact-icon3.png" alt="cafe3-contact-icon3" title="" width="58" height="58" />
                </Grid>
                <Grid item xs={8} sm={12} md={12}>
                  <div>
                    <h4 className='texthead'>ADDRESS</h4>
                    <p>ABC House, 6th Floor, Roof top,
                      Plot No. 432, Phulnakhra, NH-16,
                      Bhubaneswar, Odisha</p>
                  </div>
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </Grid>
            <Grid item md={0.6} sm={1} xs={0} />
            <Grid item md={2.4} sm={5.5} xs={12}>
              <Grid container mt={1}>
                <Grid item xs={3} sm={12} md={12}>
                  <img src="https://themes.muffingroup.com/be/cafe3/wp-content/uploads/2020/02/cafe3-contact-icon4.png" alt="cafe3-contact-icon4" title="" width="58" height="58" />
                </Grid>
                <Grid item xs={9} sm={12} md={12}>
                  <div>
                    <h4 className='texthead'>TIMING</h4>
                    <p>12 AM TO 5 AM</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className='form'>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <center><h4 className='formhead'>CONTACT FORM</h4>
                <h2 className='formques'>Do you have a question?</h2></center>
              <Grid container direction={'row'} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField sx={{
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    backgroundColor: "white",
                    borderRadius: "0px",
                  }} fullWidth margin='dense' type="text" aria-required="true" placeholder="Your name" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField sx={{
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    backgroundColor: "white",
                    borderRadius: "0px",
                  }} fullWidth margin='dense' type="email" aria-required="true" placeholder="Your e-mail" />
                </Grid>
              </Grid>
              <Grid container marginTop={1.5}>
                <Grid item xs={12} sm={12}>
                  <TextField sx={{
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    backgroundColor: "white",
                    borderRadius: "0px",
                  }} fullWidth type="text" aria-required="true" placeholder="Subject" />
                </Grid>
              </Grid>
              <Grid container marginTop={1.5}>
                <Grid item xs={12} sm={12}>
                  <TextField variant="outlined"
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      backgroundColor: "white",
                      borderRadius: "0px",
                      height: '160px',
                    }} fullWidth multiline={4} type="text" aria-required="true" placeholder="Message" />
                </Grid>
              </Grid>
              <Grid marginTop={5} marginBottom={10}>
                <center>
                  <Button className='abtbtn' variant='contained' disableRipple disableElevation>
                    Send A Message
                  </Button>
                </center>
              </Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Contact />
        </Grid>
      </Grid>
    )
  }
}
