import React, { Component } from "react";
import { connect } from "react-redux";
import Routes from "../components/router";

export class Routes_con extends Component {

  render() {
    return (
      <Routes {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,

  };
};

export const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes_con);